import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import {database} from "@/database";

// Load lazy components
function loadComponent(component: string) {
    return () => import(`@/views/${component}View.vue`);
}

const routes: Array<RouteRecordRaw> = [
    {
        path: "/login",
        name: "login",
        component: loadComponent("Login")
    },
    {
        path: "/",
        name: "home",
        component: loadComponent("Home"),
        children: [
            {
                path: "",
                name: "redirect_to_road",
                redirect: () => "road"
            },
            {
                path: "/road",
                name: "road",
                component: loadComponent("home/Road")
            },
            {
                path: "/itinerary",
                name: "itinerary",
                component: loadComponent("home/Itinerary")
            },
            {
                path: "/road/days/:id/:road",
                name: "day",
                component: loadComponent("home/Day")
            },
            {
                path: "/road/days/:id/:road/travel/:type?/:point?",
                name: "travel",
                component: loadComponent("home/Travel")
            },
            {
                path: "/documents",
                name: "documents",
                component: loadComponent("document/Documents")
            },
            {
                path: "/news",
                name: "news",
                component: loadComponent("news/News")
            },
            {
                path: "/help",
                name: "",
                component: loadComponent("help/Info"),
                children: [
                    {
                        path: "",
                        name: "help",
                        component: loadComponent("help/Help")
                    },
                    {
                        path: "/help/:category/questions",
                        name: "categories_questions",
                        component: loadComponent("help/CategoriesQuestions")
                    }
                ]
            }
        ]
    },
    {
        path: "/:pathMatch(.*)*",
        name: "wildcard",
        redirect: {name: "road"}
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(async (to, from, next) => {
    // Check if travel code exist on local storage and if travel exist on IndexedDB
    const travel = await database.getTravel();
    if (to.name !== "login" && travel === undefined) next({name: "login"});
    else if (to.name === "login" && travel !== undefined) next({name: "road"});
    else next();
});

export default router;
