import { dom, library } from "@fortawesome/fontawesome-svg-core";
import {
    faMap,
    faArrowLeft,
    faArrowRight,
    faAddressCard,
    faCircleInfo,
    faChevronRight,
    faRoad,
    faDownload,
    faBars,
    faXmark,
    faBuilding,
    faLocationCrosshairs,
    faBed,
    faPlay,
    faUtensils,
    faBinoculars,
    faExclamation,
    faChevronLeft,
    faXmarkCircle,
    faGear,
    faLifeRing,
    faFile,
    faRoute,
    faParking,
    faFileLines,
    faHeart,
    faRightFromBracket,
    faChartLine,
    faNewspaper,
    faLocationPinLock,
    faMapLocationDot,
    faFileExport,
    faCloudArrowDown,
    faArrowsRotate,
    faLanguage,
    faMaximize,
    faMinus,
    faPlus,
    faLocationArrow,
    faCircleCheck,
    faBicycle,
    faSquareParking,
    faChevronDown,
    faChevronUp
} from "@fortawesome/free-solid-svg-icons";
import { faClock, faMap as faMapRegular, faCompass as faCompassRegular } from "@fortawesome/free-regular-svg-icons";

library.add(faMap, faMapRegular, faArrowLeft, faArrowRight, faAddressCard, faCircleInfo, faChevronRight, faRoad, faClock,
    faDownload, faBars, faXmark, faBuilding, faLocationCrosshairs, faBed, faPlay, faUtensils, faBinoculars, faExclamation,
    faChevronLeft, faXmarkCircle, faGear, faLifeRing, faFile, faRoute, faParking, faFileLines, faHeart,
    faRightFromBracket, faChartLine, faNewspaper, faLocationPinLock, faMapLocationDot, faFileExport, faCloudArrowDown,
    faArrowsRotate, faLanguage, faMaximize, faCompassRegular, faMinus, faPlus, faLocationArrow, faCircleCheck, faBicycle,
    faSquareParking, faChevronDown, faChevronUp);
dom.watch();
