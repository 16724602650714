import {createStore} from "vuex";

export interface State {
    application: { update: boolean, worker: ServiceWorkerRegistration | null };
    questionsCategories: Map<number, string>;
    map: { checkOfflineTiles: boolean, downloadingTiles: boolean };
}

export default createStore({
    state() {
        return {
            application: {
                update: false,
                worker: null
            },
            questionsCategories: new Map(),
            map: {
                checkOfflineTiles: false,
                downloadingTiles: false
            }
        };
    },
    getters: {
        updateApplication(state: State) {
            return state.application.update;
        },
        getQuestionsCategories(state: State) {
            return state.questionsCategories;
        },
        getMapCheckOfflineTiles(state: State) {
            return state.map.checkOfflineTiles;
        },
        getMapDownloadingTiles(state: State) {
            return state.map.downloadingTiles;
        }
    },
    mutations: {
        enableUpdateApplication(state: State, worker: ServiceWorkerRegistration) {
            state.application.update = true;
            state.application.worker = worker;
        },
        setQuestionsCategories(state: State, categories: Map<number, string>) {
            state.questionsCategories = categories;
        },
        setMapCheckOfflineTiles(state: State, check: boolean) {
            state.map.checkOfflineTiles = check;
        },
        setMapDownloadingTiles(state: State, downloading: boolean) {
            state.map.downloadingTiles = downloading;
        }
    },
    actions: {},
    modules: {}
});
