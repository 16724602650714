import {createApp} from "vue";
import VueToast from "vue-toast-notification";
// eslint-disable-next-line
// @ts-ignore
import VueMatomo from "vue-matomo";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "./icons";
import {database} from "@/database";
import {events} from "@/services/events";

import "vue-toast-notification/dist/theme-sugar.css";
import "./assets/less/application.less";
import {ApplicationUtils} from "@/utils/ApplicationUtils";
import {Debug} from "@/services/debug";

const app = createApp(App).use(i18n).use(store).use(router).use(VueToast).use(VueMatomo, {
    host: "https://matomo.voyelle.fr",
    siteId: 25,
    router,
});
if (process.env.VUE_APP_DEBUG) app.use(Debug.plugin());
app.config.globalProperties.eventBus = events;
app.config.globalProperties.$database = database;
// Update i18n with database value
if (database.getLocale() !== null) i18n.global.locale.value = <string>database.getLocale();
app.mount("#app");

// Quick hack to keep sidebar at bottom
window.onresize = () => {
    document.body.style.height = window.innerHeight.toString() + "px";
};
document.body.style.height = window.innerHeight.toString() + "px";

// Prevent PWA install popup
window.addEventListener("beforeinstallprompt", (e: BeforeInstallPromptEvent) => {
    e.preventDefault();
    ApplicationUtils._promptPwa = e;
    ApplicationUtils.notifyInstallPwa();
});
// Prompt on iOS
if (/iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase())) {
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
    if (!isInStandaloneMode()) {
        ApplicationUtils.notifyInstallPwa();
    }
}
