import mitt from "mitt";

export interface DisplayReturnEvent {
    hide?: boolean;
    display?: boolean;
    text?: string;
    minimize?: boolean;
}

export type Events = {
    displayNavigation: boolean;
    displayReturn: DisplayReturnEvent;
    travelLoadingText: string;
    "toast.info": string;
    "toast.error": string;
    "displayInstallPwa": boolean;
}

export const events = mitt<Events>();
