<template>
  <InstallPwaComponent v-if="displayInstallPwa"/>
  <router-view/>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import InstallPwaComponent from "@/components/InstallPwaComponent.vue";
import {DisplayReturnEvent} from "@/services/events";

export default defineComponent({
  name: "App",
  components: {InstallPwaComponent},
  data() {
    return {
      enableHeaderLogo: true,
      enableHeaderText: false,
      enableReturn: false,
      enableNavigation: true,
      headerContent: "",
      displayInstallPwa: false
    };
  },
  methods: {
    displayReturn(data: DisplayReturnEvent) {
      const display = data.display;
      const text = data.text;
      this.enableReturn = display ?? false;
      if (display && typeof text === "string") {
        this.headerContent = text;
        this.enableHeaderText = true;
        this.enableHeaderLogo = false;
      } else {
        this.enableHeaderText = false;
        this.enableHeaderLogo = true;
      }
    },
    displayNavigation(display: boolean) {
      this.enableNavigation = display;
    },
    displayInfoToast(message: string) {
      this.$toast.info(message);
    },
    displayErrorToast(message: string) {
      this.$toast.error(message);
    }
  },
  beforeMount() {
    this.eventBus.on("displayReturn", this.displayReturn);
    this.eventBus.on("displayNavigation", this.displayNavigation);
    this.eventBus.on("toast.info", this.displayInfoToast);
    this.eventBus.on("toast.error", this.displayErrorToast);
    this.eventBus.on("displayInstallPwa", (d) => {
      this.displayInstallPwa = d;
    });
  }
});
</script>

<style lang="less">
</style>
