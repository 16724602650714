import store from "@/store";
import {events} from "@/services/events";

export class ApplicationUtils {
    private static _refreshingApp = false;
    public static _promptPwa: BeforeInstallPromptEvent;

    /**
     * Update app with service worker
     * @param {() => void} callback
     */
    public static updateApp(callback?: () => void) {
        if (store.state.application.update && store.state.application.worker !== null) {
            const worker = store.state.application.worker;
            if (!worker.waiting) return;
            worker.waiting.postMessage({type: "SKIP_WAITING"});
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (ApplicationUtils._refreshingApp) return;
                ApplicationUtils._refreshingApp = true;
                if (callback !== undefined) callback(); // Call callback if is defined
                // Here the actual reload of the page occurs
                window.location.reload();
            });
        }
    }

    public static notifyInstallPwa(install = true) {
        events.emit("displayInstallPwa", install);
    }

    public static promptInstallPwa() {
        ApplicationUtils.notifyInstallPwa(false);
        ApplicationUtils._promptPwa.prompt();
    }

    public static getVersion() {
        return process.env.VUE_APP_VERSION;
    }
}
