/* eslint-disable */

import {register} from "register-service-worker";
import store from "@/store";

if (process.env.NODE_ENV === "production") {
    register(`${process.env.BASE_URL}service-worker.js`, {
        updated(worker: ServiceWorkerRegistration) {
            store.commit("enableUpdateApplication", worker);
        }
    });
}
