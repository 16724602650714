export default {
  "road": {
    "number_nights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("x")), " nights"])},
    "complete_itinerary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete itinerary"])}
  },
  "help": {
    "search_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a question"])},
    "search_no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No result found for your search"])},
    "help_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "share_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share my position"])},
    "update_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update data"])},
    "updating_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updating data"])},
    "share_position_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We thank you for <strong>providing the main elements</strong> to identify where you are <strong>then press the blue button</strong> \"<i>Share my position</i>\"."])},
    "share_position_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify your location here. Example: We are warm in the Sports Bar-Café located at Liberty Square."])}
  },
  "news": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
    "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover"])}
  },
  "document": {
    "generic_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General documents"])}
  },
  "address": {
    "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])}
  },
  "day": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])},
    "gpx_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPX files"])},
    "rider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bike shops"])}
  },
  "travel": {
    "show_altimeter_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show the altimeter profile"])},
    "hide_altimeter_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide the altimeter profile"])},
    "map_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Center"])},
    "speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speed"])},
    "elevation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elevation"])},
    "offline": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline mode"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline mode allows you to navigate even without an Internet connection."])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading offline data"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download map"])},
      "maps_already_downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maps already downloaded"])},
      "delete_downloaded_maps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete downloaded maps"])}
    }
  },
  "my_stay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My trip"])},
  "secret_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret code"])},
  "practical_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practical information"])},
  "my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
  "easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy"])},
  "leisure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leisure"])},
  "fitness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitness"])},
  "expert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert"])},
  "adventure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adventure"])},
  "see_route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See the road"])},
  "program_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program of the day"])},
  "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation"])},
  "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call"])},
  "itinerary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itinerary"])},
  "starting_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting city"])},
  "arrival_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival city"])},
  "locate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locate"])},
  "error_occurred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred"])},
  "internal_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An internal error has occurred"])},
  "unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown error, contact your advisor"])},
  "delete_profile_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove my trip from the app"])},
  "confirm_profile_deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete all your data?"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "wait_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for location"])},
  "update_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update app"])},
  "where_dine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where to dine"])},
  "where_lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where to lunch"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "internet_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No connection"])},
  "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, try again"])},
  "switch_connection_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enable internet to retrieve your trip"])},
  "travel_recovery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel recovery"])},
  "saving_trip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving the trip"])},
  "recovery_gpx_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery of GPX files"])},
  "saving_gpx_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving GPX files"])},
  "unable_save_gpx_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to save GPX files"])},
  "unable_retrieve_gpx_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to retrieve GPX files"])},
  "unable_save_trip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to save trip"])},
  "heritage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heritage"])},
  "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
  "diners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diner"])},
  "lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch"])},
  "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store"])},
  "install_pwa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install the app on your device"])},
  "install_pwa_ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install the application on your device by adding it to your welcome page"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
  "complete_itinerary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete itinerary"])},
  "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking"])},
  "where_park": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where to park"])},
  "shop_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
  "faq_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently asked"])},
  "position_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share my location"])},
  "documents_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel documents"])},
  "recits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "offline_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline mode"])},
  "more_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More informations"])},
  "download_gpx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download GPX file"])}
}