import Dexie, {Table} from "dexie";
import {Api} from "@/api";
import Travel = Api.Travel;

export interface Tile {
    key: string;
    image: Blob;
}

export interface GPXPath {
    path: string;
    data: Blob;
}

export class Database extends Dexie {
    travel!: Table<Api.Travel>;
    GPXPath!: Table<GPXPath>
    tiles!: Table<Tile>;

    constructor() {
        super(process.env.VUE_APP_DATABASE_NAME || "AbicycletteCarnetDeRoute");
        this.version(process.env.VUE_APP_DATABASE_VERSION || 1).stores({
            travel: "code",
            GPXPath: "path",
            tiles: "key"
        });
    }

    /**
     * Get saved Travel code
     * @return {string | null}
     */
    public getTravelCode(): string | null {
        return localStorage.getItem("code");
    }

    /**
     * Save Travel code
     * @param {string} code
     */
    public setTravelCode(code: string): void {
        localStorage.setItem("code", code);
    }

    /**
     * Remove code of localStorage
     */
    public clearTravelCode(): void {
        localStorage.removeItem("code");
    }

    /**
     * Get saved Travel name
     * @return {string | null}
     */
    public getTravelName(): string | null {
        return localStorage.getItem("name");
    }

    /**
     * Save Travel name
     * @param {string} name
     */
    public setTravelName(name: string): void {
        localStorage.setItem("name", name);
    }

    /**
     * Remove name of localStorage
     */
    public clearTravelName(): void {
        localStorage.removeItem("name");
    }

    /**
     * Get saved Travel start date
     * @return {number | null}
     */
    public getTravelStartDate(): number | null {
        const d = localStorage.getItem("startDate");
        return d ? parseInt(d) : null;
    }

    /**
     * Set travel start date
     * @param {number} startDate
     */
    public setTravelStartDate(startDate: number = Date.now()): void {
        localStorage.setItem("startDate", startDate.toString());
    }

    /**
     * Remove start date of localStorage
     */
    public clearTravelStartDate(): void {
        localStorage.removeItem("startDate");
    }

    /**
     * Get last date of update
     * @return {number | null}
     */
    public getLastUpdateDate(): number | null {
        const d = localStorage.getItem("lastUpdate");
        return d ? parseInt(d) : null;
    }

    /**
     * Set last date of update
     * @param {number} lastUpdate
     */
    public setLastUpdateDate(lastUpdate: number = Date.now()): void {
        localStorage.setItem("lastUpdate", lastUpdate.toString());
    }

    /**
     * Remove last update date of localStorage
     */
    public clearLastUpdateDate(): void {
        localStorage.removeItem("lastUpdate");
    }

    /**
     * Get saved Travel deleteAt
     * @return {number | null}
     */
    public getTravelDeleteAt(): number | null {
        const d = localStorage.getItem("deleteAt");
        return d ? parseInt(d) : null;
    }

    /**
     * Save Travel deleteAt
     * @param {number} deleteAt
     */
    public setTravelDeleteAt(deleteAt: number): void {
        localStorage.setItem("deleteAt", deleteAt.toString());
    }

    /**
     * Remove deleteAt of localStorage
     */
    public clearTravelDeleteAt(): void {
        localStorage.removeItem("deleteAt");
    }

    /**
     * Return travel of IndexedDB
     * @return {Promise<Api.Travel | undefined> | undefined}
     */
    public getTravel(): Promise<Api.Travel | undefined> | undefined {
        if (this.getTravelCode() !== null) return this.travel.get({code: this.getTravelCode()});
        else return undefined;
    }

    /**
     * Save travel in IndexedDB and save code in localStorage
     * @param {Api.Travel} travel
     * @return {Promise<boolean>}
     */
    public saveTravel(travel: Travel): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (typeof travel.code === "string") {
                this.travel.put(travel).then(() => {
                    this.setTravelCode(travel.code as string);
                    resolve(true);
                }).catch(() => {
                    reject(false);
                });
            } else reject(false);
        });
    }

    /**
     * Clear travel on IndexDB and code saved on localStorage
     * @return {Promise<boolean | null>}
     */
    public clearTravel(): Promise<boolean | null> {
        return new Promise((resolve, reject) => {
            const code = this.getTravelCode();
            if (code !== null) {
                this.travel.delete(code).then(() => {
                    resolve(true);
                }).catch(() => {
                    reject(null);
                });
            } else reject(null);
        });
    }

    /**
     * Remove all GPX files of database
     * @return {Promise<boolean | null>}
     */
    public clearGPXPath(): Promise<boolean | null> {
        return new Promise((resolve, reject) => {
            database.GPXPath.clear().then(() => {
                resolve(true);
            }).catch(() => {
                reject(null);
            });
        });
    }

    public tileExist(key: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.tiles.get(key).then((tile) => {
                resolve(tile !== undefined);
            }).catch(() => {
                reject(false);
            });
        });
    }

    public getTile(key: string): Promise<Tile | undefined> {
        return this.tiles.get(key);
    }

    public saveTile(key: string, tile: Tile): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.tiles.put(tile, key).then(() => {
                resolve(true);
            }).catch(() => {
                reject(false);
            });
        });
    }

    public getLocale(): string | null {
        return localStorage.getItem("locale");
    }

    public setLocale(locale: string): void {
        localStorage.setItem("locale", locale);
    }
}

export const database = new Database();
