import {App, Plugin} from "vue";
import {database} from "@/database";

export class Debug {
    private static enabled = false;
    private static instance: Debug;
    private static app: App;

    private $debugTag!: HTMLDivElement;

    private $debugContainer!: HTMLDivElement;
    private $debugContainerPositions = {x: 0, y: 0};
    private $debugChildren: Map<string, HTMLElement> = new Map();

    public static plugin(): Plugin {
        return {
            install(app: App) {
                if (localStorage.getItem("debug") === null) localStorage.setItem("debug", "1");
                Debug.enabled = localStorage.getItem("debug") === "1";
                Debug.app = app;
                app.config.globalProperties.$debug = Debug.get();
                if (Debug.isEnabled()) {
                    Debug.get().displayDebugTag();
                }
            }
        };
    }

    private displayDebugTag() {
        if (this.$debugTag === undefined) {
            this.$debugTag = document.createElement("div");
            this.$debugTag.style.position = "fixed";
            this.$debugTag.style.backgroundColor = "red";
            this.$debugTag.style.color = "white";
            this.$debugTag.style.padding = "3px 10px";
            this.$debugTag.style.transform = "rotateZ(45deg)";
            this.$debugTag.innerHTML = "<span>Debug mode</span>";
            // Calculate size
            this.$debugTag.style.opacity = "0";
            document.body.appendChild(this.$debugTag);
            const size = this.$debugTag.getBoundingClientRect();
            const width = size.width + 60;
            this.$debugTag.style.width = width + "px";
            this.$debugTag.style.opacity = "1";
            this.$debugTag.style.top = 30 + "px";
            this.$debugTag.style.left = (document.body.offsetWidth - (size.width + 30)) + "px";
            this.$debugTag.style.zIndex = "950";
            this.$debugTag.style.justifyContent = "center";
        }
        this.$debugTag.style.display = "flex";
    }

    private hideDebugTag() {
        this.$debugTag.style.display = "none";
    }

    public static isEnabled() {
        return Debug.enabled;
    }

    public static enable() {
        Debug.enabled = true;
        localStorage.setItem("debug", "1");
        Debug.get().displayContainer();
        Debug.get().displayDebugTag();
    }

    public static disable() {
        Debug.enabled = false;
        localStorage.setItem("debug", "0");
        Debug.get().hideContainer();
        Debug.get().hideDebugTag();
    }

    public static get() {
        if (Debug.instance === undefined) Debug.instance = new Debug();
        return Debug.instance;
    }

    public travelName() {
        return database.getTravelName();
    }

    public travelCode() {
        return database.getTravelCode();
    }

    public debugPosition(position: GeolocationPosition) {
        const text = `
            Latitude : ${position.coords.latitude}°<br/>
            Longitude : ${position.coords.longitude}°<br/>
            Altitude : ${position.coords.altitude}m<br/>
            Accuracy : ${position.coords.accuracy}m<br/>
            Altitude accuracy : ${position.coords.altitudeAccuracy}m<br/>
            Heading : ${position.coords.heading}°<br/>
            Speed : ${position.coords.speed}m/s<br/>
            `;
        this.writeOnDebug(text, {
            selector: "debug-debug_position"
        });
    }

    public getDebugContainer(): HTMLDivElement {
        const width = 80;
        if (this.$debugContainer === undefined) {
            this.$debugContainer = document.createElement("div");
            this.$debugContainer.style.position = "fixed";
            this.$debugContainer.style.backgroundColor = "white";
            this.$debugContainer.style.minWidth = `${width}px`;
            this.$debugContainer.style.minHeight = "50px";
            this.$debugContainer.style.top = "50px";
            this.$debugContainer.style.left = (document.body.offsetWidth - 50 - width) + "px";
            this.$debugContainer.style.fontSize = "10px";
            this.$debugContainer.style.color = "black";
            this.$debugContainer.style.padding = "5px";
            this.$debugContainer.style.zIndex = "1000";
            this.$debugContainer.style.touchAction = "none";
            (<HTMLDivElement>Debug.app._container).appendChild(this.$debugContainer);
            this.$debugContainer.addEventListener("mousedown", this.mouseDownEvent.bind(this));
            this.$debugContainer.addEventListener("touchstart", this.touchDownEvent.bind(this), {passive: true});
        }
        return this.$debugContainer;
    }

    public displayContainer() {
        if (this.$debugContainer !== undefined) this.$debugContainer.style.display = "block";
    }

    public hideContainer() {
        if (this.$debugContainer !== undefined) this.$debugContainer.style.display = "none";
    }

    private mouseDownEvent(e: MouseEvent) {
        e.preventDefault();
        this.$debugContainer.style.opacity = ".8";
        this.$debugContainerPositions.x = e.clientX;
        this.$debugContainerPositions.y = e.clientY;
        document.onmousemove = this.mouseMoveEvent.bind(this);
        document.ontouchmove = this.touchMoveEvent.bind(this);
        document.onmouseup = this.mouseUpEvent.bind(this);
        document.ontouchend = this.mouseUpEvent.bind(this);
    }

    private touchDownEvent(e: TouchEvent) {
        if (e.touches.length > 0) {
            const t = e.touches[0];
            this.$debugContainer.style.opacity = ".8";
            this.$debugContainerPositions.x = t.clientX;
            this.$debugContainerPositions.y = t.clientY;
            document.onmousemove = this.mouseMoveEvent.bind(this);
            document.ontouchmove = this.touchMoveEvent.bind(this);
            document.onmouseup = this.mouseUpEvent.bind(this);
            document.ontouchend = this.mouseUpEvent.bind(this);
        }
    }

    private mouseMoveEvent(e: MouseEvent) {
        e.preventDefault();
        const x = this.$debugContainerPositions.x - e.clientX;
        const y = this.$debugContainerPositions.y - e.clientY;
        this.$debugContainerPositions.x = e.clientX;
        this.$debugContainerPositions.y = e.clientY;
        this.$debugContainer.style.top = (this.$debugContainer.offsetTop - y) + "px";
        this.$debugContainer.style.left = (this.$debugContainer.offsetLeft - x) + "px";
    }

    private touchMoveEvent(e: TouchEvent) {
        if (e.touches.length > 0) {
            const t = e.touches[0];
            const x = this.$debugContainerPositions.x - t.clientX;
            const y = this.$debugContainerPositions.y - t.clientY;
            this.$debugContainerPositions.x = t.clientX;
            this.$debugContainerPositions.y = t.clientY;
            this.$debugContainer.style.top = (this.$debugContainer.offsetTop - y) + "px";
            this.$debugContainer.style.left = (this.$debugContainer.offsetLeft - x) + "px";
        }
    }

    private mouseUpEvent() {
        this.$debugContainer.style.opacity = "1";
        document.onmousemove = null;
        document.ontouchmove = null;
        document.onmouseup = null;
        document.ontouchend = null;
    }

    public writeOnDebug(message: string, options: { selector?: string } = {}) {
        const selector = options.selector || Date.now().toString();
        let element = this.$debugChildren.get(selector);
        if (element === undefined) {
            element = document.createElement("div");
            element.id = selector;
            this.getDebugContainer().appendChild(element);
            this.$debugChildren.set(selector, element);
        }
        element.innerHTML = message;
    }
}
